import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CardSizeOption.scss';
import { imageUrl } from '../../util';

function CardSizeOption({ name, label, selected, onSelect, square }) {
    // name here means size name


    const handleClick = () => {
        if (onSelect) {
            onSelect(name);
        }
    };

    const classes = classNames('game-card', 'vertical', name, {
        selected: selected,
        square: square
    });
    return (
        <div key={name} className='card-settings' onClick={handleClick}>
            <div className={classes}>
                <img
                    className={classNames('img-fluid', 'img-card')}
                    src={imageUrl('the-light-space-monk', square)}
                />
            </div>
            <span className='bg-label'>{label}</span>
        </div>
    );
}

CardSizeOption.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.bool
};

export default CardSizeOption;
