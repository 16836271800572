import React from 'react';
import classNames from 'classnames';
import { Popover, OverlayTrigger, PopoverBody } from 'react-bootstrap';

import DeckStatusSummary from './DeckStatusSummary';

import './DeckStatus.scss';

const DeckStatus = ({ status }) => {
    let statusName;
    let className = classNames('deck-status', {
        invalid:
            !status.basicRules,
        valid: status.basicRules
    });

    if (
        !status.basicRules
    ) {
        statusName = 'Invalid';
    } else {
        statusName = 'Valid';
    }

    const popover = (
        <Popover id='deck-status-popover'>
            <PopoverBody>
                <div>
                    <DeckStatusSummary status={status} />
                </div>
            </PopoverBody>
        </Popover>
    );

    return (
        <OverlayTrigger placement='right' overlay={popover}>
            <span className={className}>{statusName}</span>
        </OverlayTrigger>
    );
};

export default DeckStatus;
