import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SnakeDraft.scss';
import ZoomableCard from '../Decks/ZoomableCard';
import { sendSocketMessage } from '../../redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ChampionPod from './ChampionPod';

const SnakeDraft = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.account.user);
    const currentGame = useSelector((state) => state.lobby.currentGame);

    const draftState = currentGame.draftState;
    const myChoices = draftState?.playerChoices[user.username];
    const myDiscard = draftState?.playerDiscards[user.username];
    const playerNames = Object.values(currentGame.players).map(p => p.name);
    const otherPlayerName = playerNames.find(n => n !== user.username);
    const myTurn = draftState?.choosingPlayer === user.username && !draftState?.complete;
    const theirChoices = draftState?.playerChoices[otherPlayerName];
    let headerAdvice = myTurn ? 'Your Choice' : 'Their Choice';
    let discardAdvice = '';
    if (draftState?.complete) {
        headerAdvice = 'Discard One';
        discardAdvice = 'Discard one';
        if (myDiscard) {
            headerAdvice = 'Ready!';
            discardAdvice = 'Discarded:';
        }
    }
    const onChampClick = (champId) => {
        if (!myChoices || myChoices.length < 4) {
            dispatch(sendSocketMessage('draftChamp', currentGame.id, champId));
        }
    }
    const isChampTaken = (id) => {
        return theirChoices?.includes(id) || myChoices?.includes(id);
    }

    const onChampDiscard = (champId) => {
        if (myChoices?.length === 4) {
            dispatch(sendSocketMessage('discardChamp', currentGame.id, champId));
        }
    }

    const championList = draftState?.champSet.filter(c => !isChampTaken(c.id));
    return (
        <div className='lobby-card'>
            <div className='lobby-header'>Pod Draft - {headerAdvice}</div>
            <div className='champ-set'>
                <div className='champ-group'>
                    <div className='snake-controls'>
                        <div>Your Picks</div>
                        {myChoices?.length === 4 && <FontAwesomeIcon icon={faCheck} className='complete' />}
                    </div>
                    {myChoices && myChoices.filter(mc => mc !== myDiscard).map(c =>
                        <div key={c} className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: c }} noIndex={true}
                                onClick={() => onChampDiscard(c)}
                            />
                        </div>
                    )}
                </div>
                <div className='champ-group'>
                    <div className='snake-controls'>
                        <div>{discardAdvice}</div>
                    </div>
                    {myDiscard && (
                        <div className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: myDiscard }} noIndex={true}
                            />
                        </div>
                    )}
                </div>
                <hr />

                <div className='champ-group'>
                    <div className='snake-controls'>
                        <div>Opponent's Picks</div>
                        {theirChoices?.length === 4 && <FontAwesomeIcon icon={faCheck} className='complete' />}
                    </div>
                    {theirChoices && theirChoices.map(c =>
                        <div key={c} className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: c }} noIndex={true} />
                        </div>
                    )}

                </div>

            </div>
            <hr />
            <div className='champ-set'>
                {championList.map((champion) => (
                    <ChampionPod
                        key={champion.id}
                        champion={champion}
                        onChampClick={onChampClick}
                        myTurn={myTurn}
                    />
                ))}
            </div>
        </div>
    )

}

SnakeDraft.displayName = 'SnakeDraft';

export default SnakeDraft;
