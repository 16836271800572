import React from 'react';
import { imageUrl } from '../../util';
import './StatusCondition.scss';

const StatusCondition = ({ status, onMouseOver, onMouseOut, className, innerClassName, onClick }) => {
    const imgPath = imageUrl(status.statusType);
    return (
        <div className={className} title={status.statusType}
            onMouseOver={() => onMouseOver({ id: status.statusType, imageStub: status.statusType, status: true })}
            onMouseOut={onMouseOut}
            onClick={(event) => onClick(event, status)}
        >
            <img className={`status-image ${innerClassName}`} src={imgPath} />
        </div>
    )
};

StatusCondition.displayName = 'StatusCondition';

export default StatusCondition;
