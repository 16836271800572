import React from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Link from '../Components/Navigation/Link';

/**
 * @typedef LoginDetails
 * @property {string} username
 * @property {string} password
 */

/**
 * @typedef LoginProps
 * @property {function(LoginDetails): void} onSubmit Called when the form is submitted
 */

/**
 * @type {LoginDetails}
 */
const initialValues = {
    username: '',
    password: ''
};

/**
 * @param {LoginProps} props
 */
const Login = (props) => {
    const schema = yup.object({
        username: yup.string().required('You must specify a username'),
        password: yup.string().required('You must specify a password')
    });

    return (
        <Formik validationSchema={schema} onSubmit={props.onSubmit} initialValues={initialValues}>
            {(formProps) => (
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        formProps.handleSubmit(event);
                    }}
                >
                    <Row>
                        <Form.Group as={Col} lg='6' controlId='formGridUsername'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name='username'
                                type='text'
                                placeholder='Enter your username'
                                value={formProps.values.username}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                isInvalid={
                                    formProps.touched.username && !!formProps.errors.username
                                }
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formProps.errors.username}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId='formGridPassword'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name='password'
                                type='password'
                                placeholder='Enter your password'
                                value={formProps.values.password}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                isInvalid={
                                    formProps.touched.password && !!formProps.errors.password
                                }
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formProps.errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Link href='/forgot'>
                        Forgotten your password?
                    </Link>

                    <div className='text-center'>
                        <Button variant='primary' type='submit'>
                            Login
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Login;
