import React from 'react';
import PictureButton from './PictureButton';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, startNewGame } from '../../redux/actions';

const LobbyNavButtons = () => {
    const user = useSelector((state) => state.account.user);
    const dispatch = useDispatch();

    const handleNewGameClick = (gameType) => {
        if (!user) {
            return;
        }
        dispatch(startNewGame(gameType));
    };

    return (
        <div className='game-buttons'>
            <PictureButton
                text='Fight!'
                imageClass='pvp'
                onClick={() => handleNewGameClick('pvp')}
            />
            <PictureButton
                text='Decks'
                imageClass='decks-link'
                onClick={() => dispatch(navigate('/decks'))}
            />
        </div>
    );
};

export default LobbyNavButtons;
