import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import './SelectChampionModal.css';
import PictureButton from '../Lobby/PictureButton.jsx';
import Zoomable from '../Decks/Zoomable.jsx';

const SelectChampionsModal = ({ onClose, onConfirmChoice, onChooseForMe }) => {
    const championPods = useSelector((state) => state.cards.champions || []);
    const [chosen, setChosen] = useState([]);

    const onChampClick = (champion) => {
        const newChosen = [...chosen];
        if (chosen.includes(champion)) {
            // remove
            const index = newChosen.indexOf(champion);
            newChosen.splice(index, 1);
        } else {
            if (chosen.length < 3) {
                newChosen.push(champion);
            }
        }
        setChosen(newChosen);
    };

    const submitChoice = () => {
        onConfirmChoice(chosen);
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose your Champions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='chosen-champions'>
                    <div className='image-button-filler'>
                        <div>My Choices:</div>
                        <button
                            className='btn btn-success def'
                            disabled={chosen.length !== 3}
                            onClick={() => submitChoice()}
                        >
                            Confirm
                        </button>
                    </div>
                    {chosen.map((champion) => (
                        <Zoomable key={champion} card={{ imageStub: champion.id }}>
                            <PictureButton
                                text={champion.name}
                                imageClass={champion.id}
                                mini={true}
                                onClick={() => onChampClick(champion)}
                            />
                        </Zoomable>
                    ))}
                </div>
                <hr />
                <div className='champ-selector'>
                    {championPods.map((champion) => (
                        <Zoomable key={champion} card={{ imageStub: champion.id }}>
                            <PictureButton
                                text={champion.name}
                                imageClass={champion.id}
                                mini={true}
                                onClick={() => onChampClick(champion)}
                            />
                        </Zoomable>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

SelectChampionsModal.displayName = 'SelectChampionsModal';

export default SelectChampionsModal;
