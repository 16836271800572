import React from 'react';
import Panel from '../Site/Panel';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeViewSetting, toggleSquareCards } from '../../redux/actions';
import CardSizeSelector from '../Profile/CardSizeSelector';
import FormRange from 'react-bootstrap/esm/FormRange';

const GameConfiguration = ({ optionSettings, onOptionSettingToggle }) => {
    const dispatch = useDispatch();

    // USE ACCOUNT for temporary / in game changes, use AUTH.USER for saved / profile changes
    const compactLayout = useSelector(
        (state) => state.account.user.settings.optionSettings?.compactLayout
    );
    const leftMode = useSelector((state) => state.account.user.settings.optionSettings?.leftMode);
    const selectedCardSize = useSelector((state) => state.account.user.settings.cardSize);
    const useSquareCards = useSelector((state) => state.account.user.settings.useSquareCards);

    return (
        <div>
            <Form>
                <Panel title='Game Settings'>
                    <div className='advice'>
                        Note: Changes made here will only affect the current game.
                    </div>
                    <div className='bluffTimer'>
                        Bluff Timer (seconds):
                        <FormRange
                            name='gameOptions.bluffTimer'
                            label='Bluff Timer'
                            min='0'
                            max='10'
                            tooltip='on'
                            value={optionSettings.bluffTimer}
                            onChange={(event) =>
                                onOptionSettingToggle('bluffTimer', event.target.value)
                            }
                        />
                        <br />
                        {optionSettings.bluffTimer} seconds
                    </div>
                    <br />
                    <div className='alertTimer'>
                        Alert Timer (seconds):
                        <FormRange
                            name='gameOptions.alertTimer'
                            label='Alert Timer'
                            min='0'
                            max='10'
                            tooltip='on'
                            value={optionSettings.alertTimer}
                            onChange={(event) =>
                                onOptionSettingToggle('alertTimer', event.target.value)
                            }
                        />< br />
                        {optionSettings.alertTimer} seconds
                    </div>
                    <br />
                    <Row>
                        Card Size:
                        <Row>
                            <Col xs='12'>
                                <CardSizeSelector
                                    onCardSizeSelected={(name) => {
                                        dispatch(changeViewSetting('cardSize', name));
                                    }}
                                    selectedCardSize={selectedCardSize}
                                    useSquareCards={useSquareCards}
                                    onSquareCardChange={() => {
                                        dispatch(toggleSquareCards());
                                    }}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Panel>
            </Form>
        </div>
    );
};

GameConfiguration.displayName = 'GameConfiguration';

export default GameConfiguration;
