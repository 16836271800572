import React from 'react';
import { cardSizes } from '../../util';

import CardSizeOption from './CardSizeOption';
import { Form } from 'react-bootstrap';

const CardSizeSelector = ({ selectedCardSize, onCardSizeSelected, useSquareCards, onSquareCardChange }) => {

    return <div>
        <Form.Check
            // type='switch'
            id='useSquareCards'
            label='Use square cards'
            // inline
            onChange={(e) => {
                onSquareCardChange(e.target.checked);
            }}
            value='true'
            checked={useSquareCards}
        />

        {cardSizes.map((cardSize) => (
            <CardSizeOption
                key={cardSize.name}
                label={cardSize.label}
                name={cardSize.name}
                onSelect={onCardSizeSelected}
                selected={selectedCardSize === cardSize.name}
                square={useSquareCards}
            />
        ))}

    </div>
};

export default CardSizeSelector;
