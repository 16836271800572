import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendSocketMessage } from '../../redux/actions';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import './PendingGamePlayer.scss';
import DeckStatus from '../Decks/DeckStatus';
import PlayerName from '../Site/PlayerName';
import SelectDeckModal from './SelectDeckModal';
import SelectChampionsModal from './SelectChampionsModal';

const PendingGamePlayers = ({ currentGame, user }) => {
    const [showModal, setShowModal] = useState(false);
    const [showChamps, setShowChamps] = useState(false);
    const [playerIsMe, setPlayerIsMe] = useState(true);
    const dispatch = useDispatch();

    let firstPlayer = true;
    // need to account for coaloff, and player index
    let clickHandler = (playerIsMe) => {
        setPlayerIsMe(playerIsMe);
        setShowModal(true);
    };

    const deckSelectedHandler = (deck) => {
        setShowModal(false);
        dispatch(
            sendSocketMessage('selectdeck', currentGame.id, playerIsMe, deck._id, !!deck.precon_id)
        );
    };

    const championsChosenHandler = (champions) => {
        setShowChamps(false);
        dispatch(sendSocketMessage('selectChampions', currentGame.id, champions));
    };

    const chooseForMeHandler = (deckType) => {
        setShowModal(false);
        dispatch(sendSocketMessage('selectdeck', currentGame.id, playerIsMe, -1, 0, deckType));
    };

    const doDraft = () => {
        if (!currentGame.drafting) {
            dispatch(sendSocketMessage('draft', currentGame.id));
        }
    };

    const doPodBuild = () => {
        setShowChamps(true);
    };

    return (
        <div>
            <h3>Players:</h3>
            {Object.values(currentGame.players).map((player) => {
                const isMe = player && player.name === user?.username;

                let deck = null;
                let selectLink = null;
                let status = null;

                let clickClasses = classNames('deck-selection', {
                    clickable: currentGame.gameFormat !== 'coaloff'
                });

                if (player?.deck?.selected) {
                    if (isMe || currentGame.solo) {
                        const deckName = player.deck.name;
                        deck = (
                            <button className={clickClasses} title='Select Deck' onClick={() => clickHandler(isMe)}>
                                {deckName}
                            </button>
                        );
                    } else {
                        deck = <span className='deck-selection'>Deck Selected</span>;
                    }

                    status = !(currentGame.solo && !isMe) && (
                        <DeckStatus
                            status={player.deck.status}
                        />
                    );
                } else if (player && isMe) {
                    if (currentGame.gameFormat === 'draft') {
                        selectLink = (
                            <Button onClick={() => doDraft()} disabled={currentGame.drafting} className='btn-focus def'>
                                Pod Draft
                            </Button>
                        );
                    } else if (currentGame.gameFormat === 'podcon') {
                        selectLink = (
                            <Button onClick={() => doPodBuild()} className='btn-focus def'>
                                Choose Champions
                            </Button>
                        );
                    } else {
                        selectLink = (
                            <Button onClick={() => clickHandler(isMe)} className='btn-focus def'>
                                Select Deck
                            </Button>
                        );
                    }
                }

                let rowClass = 'player-row';
                if (firstPlayer) {
                    rowClass += ' mb-2';

                    firstPlayer = false;
                }
                return (
                    <div className={rowClass} key={player.name}>
                        <div>
                            <PlayerName player={player} />
                            {deck}
                            {status}
                            {selectLink}
                        </div>
                    </div>
                );
            })}
            {showModal && (
                <SelectDeckModal
                    onClose={() => setShowModal(false)}
                    onDeckSelected={deckSelectedHandler}
                    onChooseForMe={chooseForMeHandler}
                />
            )}
            {showChamps && (
                <SelectChampionsModal
                    onClose={() => setShowChamps(false)}
                    onConfirmChoice={championsChosenHandler}
                // onChooseForMe={chooseForMeHandler}
                />
            )}
        </div>
    );
};

export default PendingGamePlayers;
