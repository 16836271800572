import React from 'react';
import classNames from 'classnames';

import './PictureButton.scss';

const PictureButton = ({
    onClick,
    text,
    imageClass,
    header,
    headerClass,
    disabled,
    mini,
    showText = true
}) => {
    const classes = classNames('image-button', imageClass, {
        mini: mini
    });
    const headerClasses = classNames('img-button-header', headerClass);

    return (
        <button className={classes} onClick={onClick} disabled={disabled}>
            {header && <div className={headerClasses}>{header}</div>}
            {showText && <div className='img-button-label'>{text}</div>}
        </button>
    );
};

export default PictureButton;
