import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

// import GameOptions from './GameOptions';
import { getStandardControlProps } from '../../util';
import { cancelNewGame, sendSocketMessage } from '../../redux/actions';

import './NewGame.scss';
import PictureButton from '../Lobby/PictureButton';
import { PatreonStatus } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import GameFormats from './GameFormats';

const GameNameMaxLength = 64;

/**
 * @typedef NewGameProps
 * @property {import("../../typedefs").GameType} [defaultGameType] The default game type to use
 * @property {boolean} [defaultPrivate] Whether or not the game defaults to private
 */

/**
 * @param {NewGameProps} props
 */
const NewGame = ({ defaultGameType, defaultPrivate, onClosed }) => {
    const lobbySocket = useSelector((state) => state.lobby.socket);
    const username = useSelector((state) => state.account.user?.username);
    const newGameType = useSelector((state) => state.lobby.newGameType);
    const user = useSelector((state) => state.account.user);
    const allowPremium = user?.patreon === PatreonStatus.Pledged || user?.permissions.isSupporter;
    const dispatch = useDispatch();

    const schema = yup.object({
        name: yup
            .string()
            .required('You must specify a name for the game')
            .max(
                GameNameMaxLength,
                `Game name must be less than ${GameNameMaxLength} characters`
            )
    });

    const initialValues = {
        name: `${username}'s game`,
        allowSpectators: true,
        gameType: 'casual',
        newGameType: newGameType,
        gameFormat: 'precon',
        gamePrivate: defaultPrivate,
        ranked: false,
        saveReplay: false
    };

    const options = [
        // { name: 'ranked', label: 'Ranked' },
        { name: 'allowSpectators', label: 'Allow spectators' },
        { name: 'showHand', label: 'Show hands to spectators' },
        { name: 'openHands', label: 'Play with open hands' }
    ];

    if (allowPremium) {
        options.push({ name: 'saveReplay', label: 'Save a replay' });
    }

    const getOptionToggle = (option, formProps) => {
        return (
            <Form.Check
                // type='switch'
                id={option.name}
                label={option.label}
                disabled={option.disabled}
                // inline
                onChange={(e) => {
                    formProps.handleChange(e);
                    if (option.name === 'ranked') {
                        formProps.setFieldValue('saveReplay', e.target.checked);
                    }
                }}
                value='true'
                checked={formProps.values[option.name]}
            />
        );
    };

    if (!lobbySocket) {
        return (
            <div>
                The connection to the lobby has been lost, waiting for it to be restored. If
                this message persists, please refresh the page.
            </div>
        );
    }

    return (
        <div>
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={(values) => {
                    dispatch(sendSocketMessage('newgame', values));
                }}
                initialValues={initialValues}
            >
                {(formProps) => (
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            formProps.handleSubmit(event);
                        }}
                    >
                        {
                            <>
                                <div className='newgame-header'>
                                    <PictureButton
                                        text='Fight!'
                                        // header='Premium'
                                        disabled={true}
                                        imageClass={newGameType}
                                    />

                                    <Form.Group as={Col} controlId='formGridGameName'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Game Name'
                                            maxLength={GameNameMaxLength}
                                            {...getStandardControlProps(formProps, 'name')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {formProps.errors.name}
                                        </Form.Control.Feedback>
                                        <GameFormats formProps={formProps} />
                                        <Form.Group>
                                            <Row>
                                                <Col xs={12} className='font-weight-bold'>Options</Col>
                                                {options.map((option) => (
                                                    <Col key={option.name} lg='6'>
                                                        {getOptionToggle(option, formProps)}
                                                        {option.disabled && (
                                                            <span className='premium-lozenge sm'>
                                                                <FontAwesomeIcon icon={faLock} />
                                                                &nbsp;Premium
                                                            </span>
                                                        )}
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Form.Group>

                                    </Form.Group>
                                </div>
                            </>
                        }

                        <div className='text-center newgame-buttons'>
                            <Button
                                variant='primary'
                                className='def'
                                onClick={() => {
                                    dispatch(cancelNewGame());
                                    if (onClosed) {
                                        onClosed(false);
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant='success' type='submit' className='def'>
                                Create
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

NewGame.displayName = 'NewGame';
export default NewGame;
