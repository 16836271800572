import React from 'react';
import discordTextLogo from '../../assets/img/discord-logo-white.svg';

const DiscordPanel = () => {
    return (
        <div className='lobby-card'>
            <a
                className='link-box-item lobby-content'
                target='_blank'
                rel='noopener noreferrer'
                href='https://discord.gg/j3TBAk4nVh'
            >
                <div>
                    <h2 className='lobby-header'>
                        <img src={discordTextLogo} className='textlogo' />
                        Join the Playtest Discord!
                    </h2>
                    <div className='d-none d-sm-block'>
                        <ul className='two-column'>
                            <li>Find other players</li>
                            <li>Talk strategy</li>
                            <li>Ask rules questions</li>
                            <li>Report a bug</li>
                        </ul>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default DiscordPanel;
