import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DeckList from '../Decks/DeckList.jsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './SelectDeckModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import PictureButton from '../Lobby/PictureButton.jsx';
import { selectDeck } from '../../redux/actions/deck.js';

const SelectDeckModal = ({ onClose, onDeckSelected, onChooseForMe }) => {
    const dispatch = useDispatch();
    const { standaloneDecks } = useSelector((state) => ({
        standaloneDecks: state.cards.standaloneDecks
    }));

    const doClick = (deck) => {
        dispatch(selectDeck(deck));
        onDeckSelected(deck);
    };

    return (
        <>
            <Modal show={true} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Deck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='banner-buttons'>
                        {standaloneDecks.map((deck) => {
                            const imgClass = deck.name.replace(' ', '-').toLowerCase();
                            return (
                                <PictureButton
                                    key={deck}
                                    imageClass={imgClass}
                                    showText={false}
                                    onClick={() => doClick(deck)}
                                />
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

SelectDeckModal.displayName = 'SelectDeckModal';

export default SelectDeckModal;
