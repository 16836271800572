import React from 'react';
import ZoomableCard from '../Decks/ZoomableCard';

const ChampionPod = ({ champion, onChampClick, myTurn }) => {
    return (
        <div key={champion.id} className='champ-group'>
            <div className='snake-controls'><div>{champion.name}</div>
                {myTurn &&
                    <button className='btn btn-primary def'
                        onClick={() => onChampClick(champion.id)}
                    >Choose</button>
                }
            </div>
            <div className={`game-card large vertical champ`}>
                <ZoomableCard card={{ imageStub: champion.id }} noIndex={true} />
            </div>
            {champion.cards.map(c => (
                <div key={c.id} className={`game-card normal  vertical`}>
                    <ZoomableCard card={{ imageStub: c.id }} noIndex={true} />
                </div>
            ))
            }
        </div>
    )
}

export default ChampionPod;