import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Col, Row } from 'react-bootstrap';

import NewGame from './NewGame';
import GameList from './GameList';
import PendingGame from './PendingGame';
import PasswordGame from './PasswordGame';
import AlertPanel from '../Site/AlertPanel';

import './GameLobby.scss';
import '../../pages/Home.scss';

import { useEffect } from 'react';
import { joinPasswordGame, sendSocketMessage, setUrl } from '../../redux/actions';
import { useRef } from 'react';
import SnakeDraft from './SnakeDraft';
import DiscordPanel from '../Lobby/DiscordPanel';
import LobbyNavButtons from '../Lobby/LobbyNavButtons';

const GameLobby = ({ gameId }) => {
    const dispatch = useDispatch();
    const filters = [
        { name: 'casual', label: 'Casual' },
        { name: 'competitive', label: 'Ranked' }
    ];
    const filterDefaults = {};

    for (const filter of filters) {
        filterDefaults[filter.name] = true;
    }

    const { games, newGame: creatingGame, currentGame, passwordGame } = useSelector((state) => ({
        games: state.lobby.games,
        newGame: state.lobby.newGame,
        currentGame: state.lobby.currentGame,
        passwordGame: state.lobby.passwordGame
    }));
    const hostingGame = currentGame && currentGame?.started === false;
    const user = useSelector((state) => state.account.user);
    const [currentFilter, setCurrentFilter] = useState(filterDefaults);
    const topRef = useRef(null);

    useEffect(() => {
        if ('Notification' in window) {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission(() => { });
            }
        }

        let filter = localStorage.getItem('gameFilter');
        if (filter) {
            setCurrentFilter(JSON.parse(filter));
        }
    }, []);

    useEffect(() => {
        if (!currentGame && gameId && games.length > 0) {
            const game = games.find((x) => x.id === gameId);

            if (!game) {
                toastr.error('Error', 'The game you tried to join was not found.');
            } else {
                if (!game.started && Object.keys(game.players).length < 2) {
                    if (game.needsPassword) {
                        dispatch(joinPasswordGame(game, 'Join'));
                    } else {
                        dispatch(sendSocketMessage('joingame', gameId));
                    }
                } else {
                    if (game.needsPassword) {
                        dispatch(joinPasswordGame(game, 'Watch'));
                    } else {
                        dispatch(sendSocketMessage('watchgame', game.id));
                    }
                }
            }
            dispatch(setUrl('/'));
        }
    }, [currentGame, dispatch, gameId, games]);

    return (
        <Row>
            <Col md='6'>
                <div className='lobby-card'>
                    {!user && (
                        <div className='text-center'>
                            <AlertPanel type='warning'>
                                Please log in to be able to start a new game
                            </AlertPanel>
                        </div>
                    )}
                    {!creatingGame && !hostingGame && <LobbyNavButtons />}
                    {creatingGame && <NewGame />}
                    {currentGame?.started === false && <PendingGame />}
                </div>
                <div ref={topRef}>{passwordGame && <PasswordGame />}</div>
            </Col>
            <Col md='6'>
                {currentGame?.drafting === true && <SnakeDraft />}

                {!currentGame?.drafting &&
                    <>
                        <div className='lobby-card'>
                            <div className='lobby-header'>Game List</div>

                            <div className='game-list'>
                                {games.length === 0 ? (
                                    <div className='no-games-notice'>
                                        No games are currently in progress
                                    </div>
                                ) : (
                                    <GameList
                                        games={games}
                                        gameFilter={currentFilter}
                                        onJoinOrWatchClick={() => topRef.current.scrollIntoView(false)}
                                    />
                                )}
                            </div>
                        </div>

                        <DiscordPanel />
                    </>
                }
            </Col>
        </Row>
    );
};

export default GameLobby;
